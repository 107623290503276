import types from '@/store/adForm/common/types.js'

const mutations = {
  [types.SET_AD_ID]: (state, adId) => {
    state.adId = adId
  },
  [types.SET_AD_FORMAT_TYPE]: (state, adFormatType) => {
    state.adFormatType = adFormatType
  },
  [types.SET_NAME]: (state, name) => {
    state.name = name
  },
  [types.SET_BRAND]: (state, brand) => {
    state.brand = brand
  },
  [types.SET_URL]: (state, url) => {
    state.url = url
  },
  [types.SET_ERRORS_NAME]: (state, name, value) => {
    state.errors[name] = value
  },
  [types.SET_All_ERRORS]: (state, errors) => {
    state.errors = errors
  },
  [types.SET_VALIDATION_WARNINGS]: (state, warnings) => {
    state.validationWarnings = warnings
  },
  [types.SET_SAVE_IS_LOADING]: (state, saveIsLoading) => {
    state.saveIsLoading = saveIsLoading
  },
  [types.SET_SIZE]: (state, size) => {
    state.size = size
  },
  [types.SET_CODE]: (state, code) => {
    state.code = code
  },
  [types.SET_ROTATION_TYPE]: (state, rotationType) => {
    state.rotationType = rotationType
  },
  [types.SET_TYPE]: (state, type) => {
    state.type = type
  },
  [types.SET_HOURS_THRESHOLD]: (state, hoursThreshold) => {
    state.hoursThreshold = hoursThreshold
  },
  [types.SET_IMPRESSIONS_THRESHOLD]: (state, impressionsThreshold) => {
    state.impressionsThreshold = impressionsThreshold
  },
  [types.SET_CREATIVES]: (state, creatives) => {
    state.creatives = creatives
  },
  [types.SET_COMMON_URL]: (state, commonUrl) => {
    state.commonUrl = commonUrl
  },
  [types.SET_SKIP_TIME]: (state, skipTime) => {
    state.skipTime = skipTime
  },
  [types.SET_MOTION_BANNER_PARAMS]: (state, { creativeIndex, motionBannerParams }) => {
    state.creatives[creativeIndex].motionBannerParams = motionBannerParams
  },
  [types.SET_REWARDED_POST_PARAMS]: (state, { creativeIndex, rewardedPostParams }) => {
    state.creatives[creativeIndex].rewardedPostParams = rewardedPostParams
  },
  [types.SET_TITLE]: (state, title) => {
    state.title = title
  },
  [types.SET_ICON]: (state, icon) => {
    state.icon = icon
  },
  [types.SET_CONTENT_FEED_NAME]: (state, contentFeedName) => {
    state.contentFeedName = contentFeedName
  },
  [types.SET_CONTENT_FEED_URL]: (state, contentFeedUrl) => {
    state.contentFeedUrl = contentFeedUrl
  },
  [types.SET_PROVIDER_WEBSITE]: (state, providerWebsite) => {
    state.providerWebsite = providerWebsite
  },
  [types.SET_CREATIVE_TOKENS]: (state, creativeTokens) => {
    state.creativeTokens = creativeTokens
  },
  [types.SET_IS_DUPLICATE]: (state, isDuplicate) => {
    state.isDuplicate = isDuplicate
  }
}

export default mutations
