import moment from 'moment'
import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
import { adTypes } from '@clickadilla/components/constants/ad-types.js'
import Approval from '@/services/classes/Approval.js'
import Campaign from '@/services/classes/Campaign.js'
import Rotation from '@/services/classes/Rotation.js'
import DirectLinkIcon from '@/services/classes/DirectLinkIcon.js'
import ValidationError from '@/services/classes/validation-error.js'
import ExceptionError from '@/services/classes/ExceptionError.js'

import BannerCreative from '@/services/classes/creatives/BannerCreative.js'
import PushCreative from '@/services/classes/creatives/PushCreative.js'
import NativeAdCreative from '@/services/classes/creatives/NativeAdCreative.js'
import InPageAdCreative from '@/services/classes/creatives/InPageAdCreative.js'
import PopunderCreative from '@/services/classes/creatives/PopunderCreative.js'
import InterstitialCreative from '@/services/classes/creatives/InterstitialCreative.js'
import IosCalendarCreative from '@/services/classes/creatives/IosCalendarCreative.js'
import InStreamCreative from '@/services/classes/creatives/InStreamCreative.js'

export default class Ads {
  actions = ''
  adFormatType = ''
  createdAt = ''
  id = null
  latestApproval = new Approval()
  model = {}
  name = ''
  size = null
  status = ''
  campaigns = []
  scanReports = []

  constructor(data) {
    if (!data) return

    this.scanReports = data.scan_reports ?? []
    this.actions = data.actions
    this.adFormatType = data.ad_format_type
    this.createdAt = moment(data.created_at).format('DD MMM HH:mm')
    this.id = data.id
    this.latestApproval = new Approval(data.latest_approval)
    this.model = data.model ? this.createModel(data.model) : {}
    this.name = data.name
    this.size = data.size
    this.status = data.status
    this.campaigns = data.campaigns?.map((campaign) => new Campaign(campaign)) ?? []
  }
  get commonUrl() {
    if (!this.model.creatives) return ''

    const creativesUrls = this.model.creatives.map((creative) => creative.url)
    const isCommonUrl = creativesUrls.length <= 1
      ? false
      : creativesUrls.every((url) => creativesUrls.every((innerUrl) => url === innerUrl))
    return isCommonUrl ? creativesUrls[0] : ''
  }
  createModel(data) {
    const model = {}
    if (this.adFormatType === adFormats.POPUNDER) {
      model.creatives = data.creatives?.map((creative) => new PopunderCreative(creative)) ?? []
    }
    if (this.adFormatType === adFormats.BANNER) {
      model.type = data.type
      model.creatives = this.createCreatives(data.creatives, data.type)
      model.rotation = new Rotation(data.rotation)
      model.code = data.code
    }
    if (this.adFormatType === adFormats.WEB_PUSH) {
      model.creatives = data.creatives?.map((creative) => new PushCreative(creative)) ?? []
      model.rotation = new Rotation(data.rotation)
    }
    if (this.adFormatType === adFormats.IN_STREAM_AD) {
      model.creatives = data.creatives?.map((creative) => new InStreamCreative(creative)) ?? []
      model.skipTime = data.skip_time
      model.type = data.type
      model.rotation = new Rotation(data.rotation)
    }
    if (this.adFormatType === adFormats.NATIVE_AD) {
      model.brand = data.brand
      model.creatives = data.creatives?.map((creative) => new NativeAdCreative(creative)) ?? []
    }
    if (this.adFormatType === adFormats.DIRECT_LINK) {
      model.src = data.src
      model.title = data.title
      model.icon = data.icon ? new DirectLinkIcon(data.icon) : null
    }
    if (this.adFormatType === adFormats.INTERSTITIAL) {
      model.code = data.code
      model.creatives = this.interstitialCreative(data.creatives, data.type)
      model.rotation = new Rotation(data.rotation)
      model.type = data.type
    }
    if (this.adFormatType === adFormats.IOS_CALENDAR) {
      model.creatives = data.creatives?.map((creative) => new IosCalendarCreative(creative)) ?? []
    }
    if (this.adFormatType === adFormats.GALLERY) {
      model.contentFeedName = data.content_feed_name
      model.contentFeedUrl = data.content_feed_url
      model.providerWebsite = data.provider_website
      model.src = data.src
    }
    if (this.adFormatType === adFormats.IN_PAGE_AD) {
      model.creatives = data.creatives?.map((creative) => new InPageAdCreative(creative)) ?? []
      model.rotation = new Rotation(data.rotation)
    }
    return model
  }
  createCreatives(creatives, type) {
    if (type === adTypes.SELFCODE) {
      return []
    }
    return creatives?.map((creative) => new BannerCreative(creative)) ?? []
  }
  interstitialCreative(creatives, type) {
    if (type === adTypes.SELFCODE) {
      return []
    }
    return creatives?.map((creative) => new InterstitialCreative(creative)) ?? []
  }
  static getDictionaryForTable() {
    return {
      adFormatType: 'ad_format_type',
      approvalStatus: 'approval_status'
    }
  }
  static responseAdError(error) {
    if (error.response && error.response.data) {
      if (error.response.data.errors) {
        const { errors } = error.response.data
        return new ValidationError(errors)
      }

      if (error.response.data.message) {
        return new ExceptionError(error.response.data.message)
      }
    }

    return error
  }

  static getPreviewRequest(creative) {
    let requestBody = {
      type: creative.type,
      ad_format_type: 'in-stream-ad',
      skip_time: creative.skipTime
    }
    if (creative.type === adTypes.MOTION_BANNER) {
      requestBody = {
        ...requestBody,
        url: creative.url,
        custom_starting_event_endpoint: creative.startingEventEndpoint,
        image_src: creative.image && creative.image.src,
        motion_banner_params: {
          fader_color: creative.motionBannerParams.faderColor,
          button_color: creative.motionBannerParams.buttonColor,
          button_hover_color: creative.motionBannerParams.buttonHoverColor,
          button_text_color: creative.motionBannerParams.buttonTextColor,
          button_text_hover_color: creative.motionBannerParams.buttonTextHoverColor,
          button_text: creative.motionBannerParams.buttonText,
          title_text: creative.motionBannerParams.titleText,
          description_text: creative.motionBannerParams.descriptionText,
          additional_text: creative.motionBannerParams.additionalText,
          text_color: creative.motionBannerParams.textColor
        }
      }
    } else if (creative.type === adTypes.REWARDED_POST) {
      requestBody = {
        ...requestBody,
        url: creative.url,
        custom_starting_event_endpoint: creative.startingEventEndpoint,
        image_src: creative.image && creative.image.src,
        icon_src: creative.icon && creative.icon.src,
        rewarded_post_params: {
          brand_text: creative.rewardedPostParams.brandText,
          button_text: creative.rewardedPostParams.buttonText,
          description_text: creative.rewardedPostParams.descriptionText,
          title_text: creative.rewardedPostParams.titleText
        }
      }
    } else if ([adTypes.WRAPPER_WITH_VIDEO, adTypes.WRAPPER_WITH_VPAID].includes(creative.type)) {
      requestBody = {
        ...requestBody,
        vast_tag: creative.vastTagUri
      }
    } else if (creative.type === adTypes.VIDEO) {
      requestBody = {
        ...requestBody,
        url: creative.url,
        video_src: creative.video && creative.video.src,
        video_params: {
          mime_type: creative.video && creative.video.mimeType,
          duration: creative.video && creative.video.customProperties.duration,
          width: creative.video && creative.video.customProperties.width,
          height: creative.video && creative.video.customProperties.height
        }
      }
    }

    return requestBody
  }

  static transformCreativeStatusRequest({ adId, creativeId, isActive }) {
    return {
      adId,
      creativeId,
      creativeStatus: isActive ? 'deactivate' : 'activate'
    }
  }

  static getStatusTypes() {
    return [
      {
        value: 'created',
        label: 'main.statuses.created',
        color: 'primary white--text',
        icon: '$created',
        iconColor: 'primary',
        iconSize: 16
      },
      {
        value: 'creating',
        label: 'main.statuses.creating',
        color: 'default',
        icon: '$creating',
        iconColor: 'warning',
        iconSize: 16
      },
      {
        value: 'archived',
        label: 'main.statuses.archived',
        color: 'default white--text',
        icon: '$archived-3',
        iconColor: 'black',
        iconSize: 16
      }
    ]
  }
}
