import { datatableFormater } from '@clickadilla/datatable-formatter'
import http from '../http.js'

export default {
  async plans(params) {
    this.plans.abort?.()
    const { data } = await http.get('/api/private/v1/traffic-provider-subscription-plans', {
      params: datatableFormater(params),
      cancelToken: new http.CancelToken((abort) => {
        this.plans.abort = abort
      })
    })
    return data.data
  },
  async subscribe(planId) {
    const { data } = await http.post('/api/private/v1/traffic-provider-subscriptions', {
      traffic_provider_subscription_plan_id: planId
    })
    return data.data
  },
  async updateSubscribe(currentPlanId, planId) {
    const { data } = await http.put(`/api/private/v1/traffic-provider-subscriptions/${currentPlanId}`, {
      traffic_provider_subscription_plan_id: planId
    })
    return data.data
  },
  async delete(planId) {
    await http.delete(`/api/private/v1/traffic-provider-subscriptions/${planId}`)
  }
}
