export default class RewardedPost {
  titleText = ''
  brandText = ''
  buttonText = ''
  descriptionText = ''

  constructor(data) {
    if (!data) return

    this.titleText = data.title_text ?? ''
    this.brandText = data.brand_text ?? ''
    this.buttonText = data.button_text ?? ''
    this.descriptionText = data.description_text ?? ''
  }
}
