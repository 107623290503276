import { adFormats } from '@clickadilla/components/constants/ad-formats.js'

const DEFAULT_PRIORITY = 100
const DEFAULT_SKIP_TIME = 5

const state = {
  defaultPriority: DEFAULT_PRIORITY,
  defaultSkipTime: DEFAULT_SKIP_TIME,
  adFormatType: adFormats.POPUNDER,
  saveIsLoading: false,
  adId: null,
  errors: {},
  validationWarnings: {},
  name: '',
  title: '',
  brand: '',
  url: '',
  commonUrl: '',
  size: '',
  code: '',
  rotationType: '',
  type: '',
  impressionsThreshold: null,
  hoursThreshold: null,
  icon: null,
  creatives: [
    {
      uuid: Math.random(),
      url: '',
      image: null,
      package: null,
      title: '',
      body: '',
      icon: null,
      description: '',
      autoGeneratedCreative: {},
      priority: DEFAULT_PRIORITY,
      isActive: true,
      startingEventEndpoint: '',
      video: null,
      vastTagUri: '',
      buttonOne: '',
      buttonTwo: '',
      rewardedPostParams: {
        brandText: '',
        buttonText: '',
        descriptionText: '',
        titleText: ''
      },
      motionBannerParams: {
        titleText: '',
        buttonText: '',
        descriptionText: '',
        additionalText: '',
        buttonTextColor: '#FFFFFF',
        faderColor: '#777777',
        textColor: '#FFFFFF',
        buttonColor: '#000000',
        buttonHoverColor: '#000000',
        buttonTextHoverColor: '#FFFFFF'
      },
      inPageSkin: {},
      inPageSkinGroup: {}
    }
  ],
  skipTime: DEFAULT_SKIP_TIME,
  contentFeedName: '',
  contentFeedUrl: '',
  providerWebsite: '',
  creativeTokens: [],
  isDuplicate: false
}

export default state
