export default {
  LOGIN: 'Login',
  REGISTER: 'Register',
  LOGOUT: 'Logout',
  UNSUBSCRIBE: 'Unsubscribe',
  CONFIRM_EMAIL: 'ConfirmEmail',
  IMPERSONATE: 'impersonate',
  RESET_PASSWORD: 'ResetPassword',
  MAIN: 'Main',
  DASHBOARD: 'Dashboard',
  NOTIFICATION: 'Notification',
  PERSONAL: 'Personal',
  VERIFICATIONS: 'Verifications',
  API: 'Api',
  TRACKING: 'Tracking',
  ADS: 'Ads',
  CREATE_AD: 'CreateAd',
  AD: 'Ad',
  EDIT_AD: 'EditAd',
  DUPLICATE_AD: 'DuplicateAd',
  CREATE_CAMPAIGN: 'CreateCampaign',
  CAMPAIGNS: 'Campaigns',
  NEW_CAMPAIGNS: 'NewCampaigns',
  CAMPAIGN: 'Campaign',
  EDIT_CAMPAIGN: 'EditCampaign',
  DUPLICATE_CAMPAIGN: 'DuplicateCampaign',
  FINANCES: 'Finances',
  CAMPAIGN_STATISTICS: 'CampaignsStatistics',
  TRAFFIC_CHART: 'TrafficChart',
  ADD_FUNDS: 'AddFunds',
  ADD_FUNDS_SUCCESS: 'AddFundsSuccess',
  ADD_FUNDS_ERROR: 'AddFundsError',
  REGISTRATION_SUCCEEDED: 'RegistrationSucceeded',
  PROVIDERS: 'Providers',
  PROVIDERS_SUBSCRIPTION: 'ProvidersSubscription',
  CREATE_PROVIDER: 'CreateProvider',
  MARKETPLACE: 'Marketplace',
  FLAT_DEAL: 'FlatDeal',
  FLAT_DEAL_OFFER: 'FlatDealOffer',
  RTB: 'Rtb',
  RTB_ENDPOINTS: 'RtbEndpoints',
  RTB_CREATE_ENDPOINT: 'RtbCreateEndpoint',
  RTB_EDIT_ENDPOINT: 'RtbEditEndpoint',
  RTB_NETWORK: 'RtbNetwork',
  RTB_CREATE_NETWORK: 'RtbCreateNetwork',
  RTB_STATISTICS: 'RtbStatistics',
  SSP_STATISTICS: 'SspStatistics',
  UPSALE: 'UPSALE',
  OFFER_CAMPAIGN: 'OFFER_CAMPAIGN',
  NOT_FOUND: '404'
}
